/*
Array of route related objects.

Property 'link' is and object with required property 'included' and an optional
'excluded' one. Each of these props MUST be an array of route name.

The array 'included' MUST be non-zero length where first item is used as 
destination route.
*/

const subHeaderArray = [
  {
    id: 1,
    title: 'Решения',
    description: '1000+ подробных и проверенных решений',
    link: {
      included: ['tasks', 'grade', 'course', 'book'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: false,
      aside: false,
    },
    noAuthDisplay: true,
    authDisplay: true,
  },

  {
    id: 2,
    title: 'Уроки',
    description: 'подробные уроки по всем темам и предметам',
    link: {
      included: ['lessons', 'lesson-root'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: false,
      aside: false,
    },
    noAuthDisplay: true,
    authDisplay: true,
  },

  {
    id: 3,
    title: 'Школа',
    description: 'онлайн образование с лучшей программой',
    link: {
      included: ['school'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: false,
      aside: false,
    },
    noAuthDisplay: true,
    authDisplay: true,
  },

  {
    id: 4,
    title: 'КазательGPT',
    description: 'поможет решить уравнение или напишет сочинение',
    link: {
      included: ['KazGPT'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: false,
      aside: false,
    },
    noAuthDisplay: true,
    authDisplay: true,
  },

  {
    id: 5,
    title: 'Рюкзак',
    description: 'сохраняй самые нужные учебники и уроки',
    link: {
      included: ['favorites'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: false,
      aside: false,
    },
    noAuthDisplay: false,
    authDisplay: true,
  },

  {
    id: 6,
    title: 'Подписка на решения',
    description: '',
    link: {
      included: ['subscriptions'],
      excluded: []
    },
    tag: {
      name: false,
      aside: false,
    },
    badge: {
      name: true,
      aside: false,
    },
    noAuthDisplay: true,
    authDisplay: true,
  },

]

export default subHeaderArray