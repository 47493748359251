<template>
  <div v-if="mode === 'toast'">
    <transition name="slide-fade">
      <div
        v-show="visibility"
        :class="[
          'notification',
          `notification_type_${appearance}`,
          `notification_mode_${mode}`
        ]"
      >
        <div class="notification__icon-wrapper">
          <svg-icon
            :class="`icon__${appearance}`" 
            :iconName="'kuiIconInfo'" 
            :size="'16px'" 
          />
        </div>
        <div class="notification__content">
          <div class="content__text">
            <p class="label label_size_M label_type_bold">{{ label }}</p>
            <p v-if="descriptionNeeded" class="label label_size_S">
              {{ description }}
            </p>
          </div>
          <div class="content__actions" v-if="actionsNeeded">
            <Button
              :label="mainActionLabel"
              :appearance="'outline'"
              :mode="'onLight'"
              :size="'S'"
            />
            <Link
              :label="secondActionLabel"
              :size="'S'"
              :appearance="'onLight'"
            />
          </div>
        </div>
        <div v-if="closeNeeded" class="notification__icon-wrapper" @click="$emit('close')">
          <svg-icon class="icon__close" :iconName="'kuiIconCloseLarge'" :size="'24px'" />
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <div
      v-show="visibility"
      :class="[
        'notification',
        `notification_type_${appearance}`,
        `notification_mode_${mode}`
      ]"
    >
      <div class="notification__icon-wrapper">
        <svg-icon
          :class="`icon__${appearance}`" 
          :iconName="'kuiIconInfo'" 
          :size="'16px'"
        />
      </div>
      <div class="notification__content">
        <div class="content__text">
          <p class="label label_size_M label_type_bold">{{ label }}</p>
          <template v-if="descriptionNeeded">
            <div v-if="$slots.description">
              <slot name="description"></slot>
            </div>
            <p v-else class="label label_size_S">
              {{ description }}
            </p>
          </template>
        </div>
        <div class="content__actions" v-if="actionsNeeded">
          <Button
            :label="mainActionLabel"
            :appearance="'outline'"
            :mode="'onLight'"
            :size="'S'"
            @click="mainAction"
          />
          <Link
            :label="secondActionLabel"
            :size="'S'"
            :appearance="'onLight'"
            @click="secondAction"
          />
        </div>
      </div>
      <div v-if="closeNeeded" class="notification__icon-wrapper" @click="$emit('close')">
        <svg-icon class="icon__close" :iconName="'kuiIconCloseLarge'" :size="'24px'" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/KazUI/atoms/button'
import Link from '@/components/KazUI/atoms/linkElement'

export default{
  name: 'notification',
  components: {
    Button,
    Link
  },
  props: {
    visibility: {
      type: Boolean,
      required: true
    },
    appearance:{
      type: String,
      required: false,
      default: 'info'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    label: {
      type: String,
      required: false,
      default: 'Title'
    },
    description: {
      type: String,
      required: false,
      default: 'Default description'
    },
    mainActionLabel: {
      type: String,
      required: false,
      default: 'First action'
    },
    secondActionLabel: {
      type: String,
      required: false,
      default: 'Second action'
    },
    actionsNeeded: {
      type: Boolean,
      default: false
    },
    descriptionNeeded: {
      type: Boolean,
      required: false,
      default: true
    },
    closeNeeded: {
      type: Boolean,
      required: false,
      default: true
    },
    mainAction: {
      type: Function,
      required: false,
      default: () => {}
    },
    secondAction: {
      type: Function,
      required: false,
      default: () => {}
    },
  },
  emits: ['close'],
}
</script>

<style scoped>
.notification{
  display: flex;
  flex-direction: row;
  /* padding-left: 16px; */
  padding: 0 16px;
  width: 100%;
  border-radius: 12px;
}
.notification_mode_inline{
  box-shadow: none;
}
.notification_mode_toast{
  box-shadow: var(--kaz-shadow-default);
  max-width: 310px;
  position: fixed;
  right: 2rem;
  top: 2rem;
}
.notification_type_info{
  background: linear-gradient(var(--kaz-status-info-bg), var(--kaz-status-info-bg)), var(--kaz-base-base-01);
}
.notification_type_warning{
  background: linear-gradient(var(--kaz-status-warning-bg), var(--kaz-status-warning-bg)), var(--kaz-base-base-01);
}
.notification_type_error{
  background: linear-gradient(var(--kaz-status-error-bg), var(--kaz-status-error-bg)), var(--kaz-base-base-01);
}
.notification_type_success{
  background: linear-gradient(var(--kaz-status-success-bg), var(--kaz-status-success-bg)), var(--kaz-base-base-01);
}
.notification__icon-wrapper{
  height: 100%;
}

.notification__icon-wrapper:first-of-type{
  padding: 15px 12px 0px 0px;
}

.icon__info{
  --kaz-base-base-09: var(--kaz-status-info-fill);
}
.icon__warning{
  --kaz-base-base-09: var(--kaz-status-warning-fill);
}
.icon__error{
  --kaz-base-base-09: var(--kaz-status-error-fill);
}
.icon__success{
  --kaz-base-base-09: var(--kaz-status-success-fill);
}
.icon__close{
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
  cursor: pointer;
}
.icon__close:hover{
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.notification__icon-wrapper:last-of-type{
  padding: 8px 0 0 0;
}

.notification__content{
  width: 100%;
  min-width: 231px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 0px;
}

.content__text{
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.content__actions{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-top: 8px;
}

@keyframes slide-fade {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  25% {
    transform: translateX(75%);
    opacity: 0.5;
  }
  50% {
    transform: translateX(50%);
    opacity: 1;
  }
  75% {
    transform: translateX(25%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-fade-enter-active {
  animation: slide-fade .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-leave, .slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}
</style>