<template>
  <card-background>
    <form @submit.prevent="submitTask">
      <kaz-loader :size="'XL'" v-if="!taskId || !restoreCompleted || isNodeFetching"/>

      <wrapper-task-entities v-else>
        <div class="select-wrapper" v-if="isModerator">
          <input-select
            label="Автор решения"
            v-model="selectedAuthor"
            :options="authorsOptions"
          />
        </div>
        <edit-task-entity
          :title="'Текст задачи'"
          :taskId="taskId"
          :collapsed="false"
          :contentType="CONTENT_TYPES.QUILL"
          :taskType="TASK_TYPES.CONDITION"
        />
        <edit-task-entity
          :title="'Текст решения задачи'"
          :taskId="taskId"
          :collapsed="false"
          :contentType="CONTENT_TYPES.QUILL"
          :taskType="TASK_TYPES.SOLUTION"
        />
        <edit-task-entity
          :title="'Видео объяснение решения'"
          :taskId="taskId"
          :collapsed="false"
          :contentType="CONTENT_TYPES.VIDEO"
          :taskType="TASK_TYPES.VIDEO"
        />
        <edit-task-entity
          :title="'Аудио объяснение решения'"
          :taskId="taskId"
          :collapsed="false"
          :contentType="CONTENT_TYPES.AUDIO"
          :taskType="TASK_TYPES.AUDIO"
        />
      </wrapper-task-entities>

      <!-- submit buttons -->
      <!-- <div class="buttons-block">
        <button-primary @click="startSave" :disabled="isDisabled || !isUploaded">
          {{ getButtonText }}
        </button-primary>
      </div> -->
      <popup-save-notification
        :showPopup="showPopup" 
        :status="savingStatus" 
        @close="showPopup = false"
      />
    </form>
  </card-background>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import ButtonSecondary from '@/components/UI/buttons/ButtonSecondary.vue';
import KazLoader from '@/components/KazUI/atoms/loader';
import InputSelect from '@/views/generation/components/InputSelect.vue';

import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import useTaskTypes from '../hooks/useTaskTypes.js';
import useContentTypes from '../hooks/useContentTypes.js';

import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb.js';


const taskReaderService = new TaskReaderClient(
  process.env.VUE_APP_REGISTRATION_SERVICE_URL, 
  null, 
  null
);


export default {
  name: 'editor-page',

  components: {
    CardBackground,
    ButtonPrimary,
    ButtonSecondary,
    KazLoader,
    InputSelect,
    WrapperTaskEntities: defineAsyncComponent({
      loader: () => import('../components/WrapperTaskEntities.vue'),
    }),
    EditTaskEntity: defineAsyncComponent({
      loader: () => import('./components/EditTaskEntity.vue'),
    }),
    PopupSaveNotification: defineAsyncComponent({
      loader: () => import('./components/PopupSaveNotification.vue'),
    })
  },

  setup() {
    const { TASK_TYPES } = useTaskTypes();
    const { CONTENT_TYPES } = useContentTypes();
    const { LOADING_STATES } = useLoadingStates();
    return { TASK_TYPES, CONTENT_TYPES, LOADING_STATES }
  },

  data() {
    return {
      nodeId: null,
      isNodeFetching: true,
      taskId: '0',
      // serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      // taskReaderService: null,
      isDisabled: false,
      showPopup: false,
      savingStatus: {
        success: true,
        errorMessage: null
      },
      contentExtractor: null,
      restoreCompleted: false,
      showEditors: true,

      selectedAuthor: [],
      authorsOptions: [],
    }
  },

  watch: {
    getLoadingState: {
      handler() {
        if (this.getLoadingState === this.LOADING_STATES.LOADED) {
          this.fetchNode();
        }
      },
      immediate: false
    },

    selectedAuthor: {
      handler(newValue, oldValue) {
        console.warn('Selected author changed:', newValue);
        if (this.getLoadingState === this.LOADING_STATES.LOADED) {
          this.fetchNode();
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      getUserData: 'userData/getUserData',
      getTaskVersion: 'quillData/getTaskVersion',
      getLoadingState: 'userData/getLoadingState',
      isUploaded: 'multistepUpload/isUploaded',
      sessionActive: 'authData/sessionActive',
      isModerator: 'userData/isModerator',
    }),
    getUserData2() {
      setTimeout(() => {
        return this.getUserData
      }, 100);
    },
    getButtonText() {
      if (this.isUploaded) {
        return 'Сохранить'
      }
      return 'Загрузка...'
    }
  },

  methods: {
    ...mapActions({
      saveAllTasks: 'quillData/saveAllTasks',
      preserveData: 'quillData/preserveData',
      restorePreservedData: 'quillData/restorePreservedData',
      freePreservedData: 'quillData/freePreservedData',
      fetchUser: 'userData/fetchUser',
    }),
    ...mapMutations({
      setTaskVersion: 'quillData/setTaskVersion',
      setTaskId: 'quillData/setTaskId',
      setProgressStepNumber: 'editorIntro/setStepNumber'
    }),

    async fetchNode() {
      try {
        console.log('start to fetch node:', this.nodeId);
        this.isNodeFetching = true;
        const requestNode = new proto.kazatel.books.Node();
        requestNode.setId(this.nodeId);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        taskReaderService.node(requestNode, metadata, this.resultHandlerNode);
      } catch (e) {
        this.isNodeFetching = false;
        console.error('Ошибка запроса информации об структурном элементе книги\n' + e.message)
      }
    },

    async resultHandlerNode(err, response) {
      if (err) {
        this.isNodeFetching = false;
        switch (err.code) {
          case 2:
            window.alert('Сервис недоступен\n' + err.message);
            break;
          case 6:
            window.alert('Пользователь с указанными данными уже зарегистрирован');
            break;
          case 14:
            window.alert('Сервис чтения заданий недоступен\n' + err.message);
            break;
          default:
            console.error('resultHandlerNode code:', err.code);
            console.error('resultHandlerNode message:', err.message);
        }
      } else {
        const tasks = response.getTasksList();
        console.log(
          '%ctasks response [editor]:',
          'background-color: #ff7d71; padding: 4px;',
          response.toObject()
        );
        // reset taskVersion and taskId in quillData storage
        this.setTaskVersion(0);
        this.setTaskId(null);

        this.authorsOptions = tasks.map(task => ({
          value: task.getUserId(),
          title: task.getUserId()
        }));

        console.log(`%cUser's login=${this.getUserData.login}`, 'color: orange;');
        for (const keyTask in tasks) {
          let currenLogin = this.getUserData.login;
          if (this.selectedAuthor.length) {
            currenLogin = this.selectedAuthor[0].value;
          }
          if (currenLogin  === tasks[keyTask].getUserId()) {
            console.log(`%cFound user's task: taskID=${tasks[keyTask].getTaskId()}`, 'color: orange;');
            this.taskId = tasks[keyTask].getTaskId();
            const versions = tasks[keyTask].getVersionsList();
            for (const keyVer in versions) {
              const versionId = versions[keyVer].getVersion();
              if (this.getTaskVersion < versionId) {
                this.setTaskVersion(versionId);
              }
            }
          }
        }
        if (!this.taskId) {
          this.taskId = '0';
        }
        this.setTaskId(this.taskId);
        this.isNodeFetching = false;
        this.showEditors = true;
      }
    },

    async startSave() {
      if (!this.sessionActive) {
        this.preserveData({
          bookId: this.$route.params.bid,
          nodeId: this.nodeId,
          taskId: this.taskId
        });
        this.$router.push({ name: 'login' });
        return
      }
      await this.save();
      this.setProgressStepNumber(5);
    },

    async save() {
      try {
        this.isDisabled = true;
        await this.saveAllTasks({
          bookId: this.$route.params.bid,
          nodeId: this.nodeId,
          taskId: this.taskId,
          contentExtractor: this.contentExtractor.default
        });
        // next line is necessary to update taskVersion...
        // if updating is not completed the button has to be disabled
        this.fetchNode();
        this.savingStatus.success = true;
        this.savingStatus.errorMessage = null;
      } catch (error) {
        this.savingStatus.success = false;
        this.savingStatus.errorMessage = error.message;
        console.error('task saving:', error);
      } finally {
        this.isDisabled = false;
        this.showPopup = true;
      }
    }
  },

  async created() {
    console.log('Start editor...')
    this.nodeId = this.$route.params.tid;
    this.contentExtractor = await import('../utils/extractTaskContent.js');

    this.restoreCompleted = false;
    const restoreResult = await this.restorePreservedData();
    if (restoreResult) {
      await this.freePreservedData();
    }
    this.restoreCompleted = true;

    if (this.getLoadingState === this.LOADING_STATES.LOADED) {
      this.fetchNode();
    }


    if(this.$route.query.author){
      this.selectedAuthor.push({value: this.$route.query.author, title: this.$route.query.author})
    }

  }
}
</script>

<style scoped>
.callabsible-image-title {
  color: #505156;
  font-weight: 600;
  font-size: 18px;
}

.section-description {
  color: #505156;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
}

.buttons-block {
  position: sticky;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}

.select-wrapper{
  max-width: 50%
}

@media screen and (max-width: 768px) {
  .select-wrapper{
    max-width: 100%
  }
}
</style>