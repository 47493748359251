import { buildTree } from '@/utils/book-structure';
import { fetchBook, saveBook, fetchNodes, fetchBookTypes, deleteBookNode } from '@/services/TaskReader';


async function loadBookInfo(bookId) {
  let resBook = {};
  try {
    const response = await fetchBook(bookId);
    const book = response.toObject().book;
    console.warn(response.toObject())
    if (book === undefined) {
      this.overviewLoadingError = { code: 7 };
    } else {
      resBook.id = book.id;
      resBook.title = book.nameOfficial;
      resBook.titleSeo = book.name;
      // resBook.fgos = response.toObject().fgos;
      resBook.fgos = book.fgos;
      // resBook.pages = response.toObject().pages;
      resBook.pages = response.toObject().pages.toString();
      resBook.subject = [{value: book.subjectId, title: book.subject} ];
      // resBook.levels = [];
      resBook.levels = book.levelsList.map(level => ({value: level, title: level}));
      resBook.publisher = book.publisher;
      resBook.cover = [book.viewUrl];
      // resBook.authors = [book.authors];
      resBook.authors = book.authors.split(',').map(author => ({value: author, title: author}));
      resBook.premium = book.attrPremium;
      resBook.publishYears = [ {title: book.publishYear} ];
      resBook.bookType = [{value: book.typeId, title: book.type } ];
      resBook.parts = [ {title: book.part} ];
      resBook.studyDegree = [ {value: 1, title: 'Углубленный уровень'} ];
      resBook.displayPriority = book.rank;
      resBook.description = book.description;
    }
  } catch (err) {
    console.error('Fetch book overview:', err.message);
  } finally {
  }
  return resBook;
}


async function loadBookStructureInfo(bookId) {
  const book = {};
  let rootId = null;
  try {
    const response = await fetchBook(bookId);
    rootId = response.getRootNodeId();

    const responseBook = response.getBook();
    book.id = responseBook.getId();
    book.titleSeo = responseBook.getName();
    book.titleSystem = responseBook.getNameOfficial();
    book.cover = responseBook.getViewUrl();
    book.authors = responseBook.getAuthors().split(',');
    book.year = responseBook.getPublishYear();
    book.type = responseBook.getType();
    book.part = responseBook.getPart();
    book.subject = responseBook.getSubject();
    book.subjectId = responseBook.getSubjectId();
    book.publisher = responseBook.getPublisher();
    book.studyDepth = 'Углубленный уровень';
    book.FGOS = responseBook.getFgos();
    book.status = 'Отсутствует структура';
    book.levels = responseBook.getLevelsList();
    book.displayPriority = responseBook.getRank();
    book.description = responseBook.getDescription();
    return { book, rootId };
  } catch (error) {
    throw error;
  }
}

async function loadStructureTree(bookId, parentId) {
  try {
    const response = await fetchNodes(bookId, parentId);
    const nodesList = response.map(res => res.toObject());
    // console.log(nodesList);
    const nodeTree = buildTree(nodesList);
    return nodeTree;
  } catch (error) {
    throw error;
  }
}


async function saveBookInfo(bookInfo) {
  console.log(bookInfo);
  try {
    return await saveBook(bookInfo);
  } catch (err) {
    console.error('Save book overview:', err.message);
  } finally {
  }
}


async function removeBookNode(nodeId) {
  console.log(nodeId);
  try {
    return await deleteBookNode(nodeId);
  } catch (err) {
    console.error('Delete node overview:', err.message);
  } finally {
  }
}


async function loadBookTypes() {
  try {
    const response = await fetchBookTypes();
    const typeList = response.map(res => (
      { 
        value: res.getId(),
        title: res.getName()
      }
    ));
    return typeList;
  } catch (error) {
    throw error;
  }
}


export {
  loadBookInfo,
  saveBookInfo,
  loadBookStructureInfo,
  loadStructureTree,
  loadBookTypes,
  removeBookNode,
}