function setContentMeta(attrName, attrValue, contentValue) {
  /**
   * Sets content attribute for given attribute name and its value of tag <meta>.
   */
  try {
    if (!attrName || !attrValue || !contentValue) {
      throw 'Params must be specified.';
    }

    if (
      typeof attrName !== 'string' ||
      typeof attrValue !== 'string' || 
      typeof contentValue !== 'string'
    ) {
      throw 'Params must have a string type.';
    }

    const metaElement = document.querySelector(`meta[${attrName}="${attrValue}"]`);
    if (metaElement) {
      metaElement.content = contentValue;
    } else {
      const newMetaElement = document.createElement('meta');
      newMetaElement.setAttribute(attrName, attrValue);
      newMetaElement.content = contentValue;
      document.head.appendChild(newMetaElement);
    }
  } catch (error) {
    console.warn('Setting content for name meta tag:', error);
  }
}

export {
  setContentMeta,
}
