<template>
  <div class="input__wrapper">
    <div class="input__label-wrapper">
      <label-text>{{ label }}</label-text>
      <span class="input__required-label" v-if="required">*</span>
    </div>
    <input
      class="input input_type_default"
      :class="{ 'uppercase': isUpperCase }"
      :type="type"
      :value="value"
      @input="updateValue"
      :placeholder="placeholder"
      :required="required"
      :min="min"
      :max="max"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :maxlength="maxlength"
      :isUpperCase="isUpperCase"
    />
  </div>
</template>

<script>
import LabelText from '@/components/UI/texts/labels/LabelText.vue';

export default {
  name: 'input-component',
  components: {
    LabelText,
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: String,
    placeholder: String,
    required: String,
    min: [String, Number],
    max: [String, Number],
    disabled:{
      type: Boolean,
      default: false
    },
    autocomplete: String,
    maxlength: [String, Number],
    isUpperCase:{
      type: Boolean,
      default: false
    },
  },
  methods:{
    updateValue(event) {
      this.$emit('update:value', event.target.value);
    }
  }
};
</script>

<style scoped>
@import './input.scss';

.input__wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input__label-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.input__required-label {
  color: var(--red-indicator);
  font-size: 24px;
  line-height: 5px;
}
.input_type_default {
  border: 2px solid var(--background-light-20);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  color: var(--text-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  transition: .3s;
}
.input_type_default::placeholder {
  width: 100%;
  color: var(--text-light);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
}
.input_type_default:hover {
  transition: .3s;
  background-color: var(--background-light);
}

.input_type_default:focus {
  border: 2px solid var(--text-light);
}

.uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 568px) {
  .input__required-label {
    font-size: 20px;
  }
  .input_type_default {
    padding: 12px;
    font-size: 14px;
  }

  .input_type_default::placeholder {
    font-size: 14px;
  }
}
</style>
